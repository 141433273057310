body {
  background-color: #f6f6f6 !important;
  font-family: "Roboto", sans-serif !important;
}

.box {
  background-color: #fff;
  border-radius: 10px;
  margin: 2rem 0 0 0;
  position: relative;
  width: 100%;
  height: auto;
  /* overflow: hidden; */
  box-shadow: 0px 2px 9px 0px rgb(0 0 0 / 23%);
}

.box__header {
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: #279655;
  display: flex;
  padding: 0.8rem;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px 10px 0 0;
}

.box__body {
  padding: 1rem;
  width: 100%;
  height: auto;
}

.box__body button {
  background: #279655 !important;
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  cursor: pointer;
}

.box__body button svg {
  margin-left: 0.3rem !important;
}

.box__header h3 {
  font-weight: 500;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 1rem;
}

.box__header-d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-danger {
  color: #fff;
  background-color: #9f3d1b !important;
  border-color: transparent !important;
}

.navbar h1 {
  font-size: 1.1rem;
  margin: 0 0 0 1rem;
  padding: 0;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.box__header button.boton {
  color: #fff;
  background-color: #198754;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid transparent;
  padding: 0.19rem 0.75rem;
  margin: 0;
  border: 1px solid #ffffff6e;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.box__header button.boton:hover {
  background-color: #127246;
}
nav.navbar-light {
  background-color: #fff !important;
  box-shadow: 2px 2px 9px rgb(0, 0, 0, 0.14) !important;
}

nav.navbar-light a.nav-link {
  color: #259754 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  padding: 5px 1rem !important;
  background: #eeeeee;
  margin-right: 0.8rem;
  border-radius: 10px;
  display: inline-block;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

a.VERDE {
  background: #259754;
  color: #fff;
  padding: 3px 10px;
  border-radius: 5px;
  margin: 3px;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

a.VERDE:hover {
  background: #259754;
  color: rgba(255, 255, 255, 0.7);
  padding: 3px 10px;
  border-radius: 5px;
  margin: 3px;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.ROJO {
  background: #ab2121;
  color: #fff;
  padding: 3px 10px;
  border-radius: 5px;
  margin: 3px;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.VERDE {
  background: #279655;
  color: #fff;
  padding: 3px 10px;
  border-radius: 5px;
  margin: 3px;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

a.AZUL {
  background: #4abfbf;
  color: #fff;
  padding: 3px 10px;
  border-radius: 5px;
  margin: 3px;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

a.AZUL:hover {
  background: #4abfbf;
  color: #fff;
  padding: 3px 10px;
  border-radius: 5px;
  margin: 3px;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}

nav.navbar-light a.nav-link:hover {
  background: #259754;
  color: #fff !important;
}

.css-tlfecz-indicatorContainer {
  background: #259754;
}

button.react-datepicker__close-icon {
  width: 48px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0 3px 3px 0 !important;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #8bc34a !important;
  color: #fff !important;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.box__body h5 {
  font-weight: 500;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
  color: #279655;
  font-size: 1rem;
  /* border-bottom: 1px solid #afd0bd; */
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 3px !important;
  margin-right: 5px !important;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-top: 1px solid #b5c9b5;
  padding-top: 0.5rem;
  border-bottom: 1px solid #b5c9b5;
  padding-bottom: 0.5rem;
  background: rgb(255 255 255 / 25%);
  justify-content: center;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.pagination button {
  color: #fff;
  background-color: #198754;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid transparent;
  padding: 0.19rem 0.75rem;
  margin: 0 0.1rem;
  border: 1px solid #ffffff6e;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.numeroActualPage {
  margin: 0 5px 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.numeroGoPage {
  margin: 0 16px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.numeroGoPage input {
  width: 100px;
  padding: 0;
  margin: 0 5px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #badbcc;
}

.numeroGoPage input:hover,
.numeroGoPage input:focus {
  border: 1px solid #76ac94 !important;
}

.numeroActualPage strong {
  margin: 0 16px;
}

table.table-hover tbody {
  overflow-y: auto;
}

.pagination select {
  width: 140px;
  padding: 0;
  margin: 0 5px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #badbcc;
}

.pagination select:hover,
.pagination select:focus {
  border: 1px solid #badbcc;
}

table.table-hover {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

table.table-hover thead {
  color: #fff;
  background-color: #279655;
  border-bottom: 2px solid rgba(0, 0, 0, 0.28);
  border-radius: 3px 3px 0 0;
  white-space: nowrap;
}

.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 0 !important;
}

.copiar {
  width: 100%;
  border-radius: 5px;
  padding: 3px 10px 3px 34px;
  border: 1px rgb(240 240 240 / 80%) solid;
  margin-left: 1rem;
  background: rgb(255 255 255 / 45%);
  color: rgba(0, 0, 0, 0.8);
  background-image: url("resources/img/arrow.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 10px 10px;
  box-shadow: 0px 2px 9px 0px rgb(0 0 0 / 23%);
}

.copiar:hover {
  outline: none;
  border-radius: 5px;
  padding: 3px 10px 3px 34px;
  border: 1px rgba(240, 240, 240, 0.8) solid;
  margin-left: 1rem;
  /* background: url('resources/img/arrow.svg') rgba(234, 249, 176, 0.45), url('resources/img/copy.svg'); */
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  background: url(resources/img/arrow.svg),
    url(resources/img/copy.svg) rgba(234, 249, 176, 0.45);
  background-size: 15px;
  background-position: 10px 10px, right center;
  background-repeat: no-repeat;
  box-shadow: 0px 2px 9px 0px rgb(0 0 0 / 23%);
}

a.btn {
  background: #279655 !important;
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem !important;
  cursor: pointer;
  white-space: nowrap;
}

a.btn i {
  margin-right: 5px;
}

.data {
  background: #ebebeb;
  padding: 5px;
  margin: 0 auto 20px;
  text-align: left;
  width: 600px;
}

.reload {
  cursor: pointer;
}

.modales {
  width: 100vw;
  height: 100vh;
  background: rgb(0 0 0 / 65%);
  position: fixed;
  top: 0;
  left: 0;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(52, 52, 52, 0.2);
  border-right: 0.5em solid rgba(52, 52, 52, 0.2);
  border-bottom: 0.5em solid rgba(52, 52, 52, 0.2);
  border-left: 0.5em solid #6c9e07;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

/* .loader::after {
  display: block;
  content: 'sscascasc';
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.8);
  position: absolute;
  top: 0;
  z-index: 0;
} */

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
  height: 300px !important;
  overflow-x: hidden;
  overflow-y: scroll !important;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
}

.box__body .buttonGroup button.danger {
  background: #ab2121 !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
}

.box__body .buttonGroup button.danger:hover {
  background: #e25050 !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
}

.box__body .buttonGroup button.edit {
  background: #d08a22 !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
}

.box__body .buttonGroup button.edit:hover {
  background: #e89a25 !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
}

.box__body .buttonGroup button.see {
  background: #1f83c6 !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
}

.box__body .buttonGroup button.see:hover {
  background: #56afeb !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
}

.box__body .buttonGroup button.success {
  background: #279655 !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
}

.box__body .buttonGroup button.success:hover {
  background: #279655 !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
}

.box__body button.true {
  background: #1f83c6 !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
}

.box__body button.true:hover {
  background: #56afeb !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
}

.box__body button.false {
  background: #ab2121 !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0;
}

.box__body button.false:hover {
  background: #e25050 !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0;
}

.box__header button.newUser {
  background: #ffffff !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
  color: #259754;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #23523cf5;
  font-weight: 900;
}

.box__header button.newUser:hover {
  background: #bbe94e !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
  color: #486504;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 1px 1px 7px rgb(0 0 0 / 32%);
}

header .groupButtom button.btnOnline {
  background: #ffffff !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
  color: #259754;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #23523cf5;
  font-weight: 900;
  border-radius: 0.5rem;
}

header .groupButtom button.btnOnline:hover {
  background: #bbe94e !important;
  font-size: 11px;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0.3rem;
  transition: all 0.3s ease-in-out;
  color: #486504;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 1px 1px 7px rgb(0 0 0 / 32%);
  border-radius: 0.5rem;
}

header button {
  background: #ffffff !important;
  font-size: 11px !important;
  padding: 0 0.75rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 0.3rem !important;
  transition: all 0.3s ease-in-out !important;
  color: #259754 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  border: 1px solid #23523cf5 !important;
  font-weight: 900 !important;
  border-radius: 0.5rem !important;
}

.box__body .buttonGroup button.danger i,
.box__body .buttonGroup button.edit i,
.box__body .buttonGroup button.see i,
.box__header button.btn.newUser i {
  font-size: 1rem;
  margin-right: 0.3rem;
}

.box__body button.true i,
.box__body button.false i {
  font-size: 0.7rem;
  margin-right: 0.3rem;
}

header .groupButtom button.btnOnline i {
  font-size: 1rem;
  margin-left: 0.3rem;
}

.spaceBetween {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.groupButtom {
  display: flex;
}

.form-group {
  margin: 0 0 1rem 0;
}

.opciones.form-group .btn {
  margin: 0.2rem;
}
